import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Link, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { PeakyBlinders } from "components/Icons/Icons";
import { Separator } from "components/Separator/Separator";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth } from 'context/AuthContext';
import { roles } from 'context/Permissions/RolesPermissions';

const SidebarContent = ({ logoText, routes }) => {
    const { userDetails } = useAuth();
    const [filteredRoutes, setFilteredRoutes] = useState([]);
    let location = useLocation();
    const [state, setState] = React.useState({});

    useEffect(() => {
        if (userDetails) {
            const userGrade = userDetails.grade;
            const accessibleRoutes = routes.filter(route => {
                if (route.hidden) {
                    return false;
                }
                if (route.requiredGrades && route.requiredGrades.length > 0) {
                    return route.requiredGrades.includes(userGrade);
                }
                return true; // Show routes that don't have grade restrictions
            });
            setFilteredRoutes(accessibleRoutes);
        }
    }, [userDetails, routes]);

    const activeRoute = (routeName) => {
        return location.pathname === routeName ? "active" : "";
    };

    const createLinks = (routes) => {
        const activeBg = useColorModeValue("white", "gray.700");
        const inactiveBg = useColorModeValue("white", "gray.700");
        const activeColor = useColorModeValue("gray.700", "white");
        const inactiveColor = useColorModeValue("gray.400", "gray.400");

        return routes.map((prop, key) => {
            if (!prop) {
                // Si prop est undefined ou null, on ignore cet élément
                return null;
            }
            if (prop.redirect) {
                return null;
            }
            if (prop.category) {
                const st = {};
                st[prop.state] = !state[prop.state];
                return (
                    <div key={prop.name}>
                        <Text
                            color={activeColor}
                            fontWeight="bold"
                            mb={{
                                xl: "12px",
                            }}
                            mx="auto"
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            py="12px"
                        >
                            {document.documentElement.dir === "rtl"
                                ? prop.rtlName
                                : prop.name}
                        </Text>
                        {createLinks(prop.views)}
                    </div>
                );
            }
            return (
                <NavLink to={prop.layout + prop.path} key={prop.name}>
                    {activeRoute(prop.layout + prop.path) === "active" ? (
                        <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            bg={activeBg}
                            mb={{
                                xl: "12px",
                            }}
                            mx={{
                                xl: "auto",
                            }}
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            py="12px"
                            borderRadius="15px"
                            _hover="none"
                            w="100%"
                            _active={{
                                bg: "inherit",
                                transform: "none",
                                borderColor: "transparent",
                            }}
                            _focus={{
                                boxShadow: "none",
                            }}
                        >
                            <Flex>
                                {typeof prop.icon === "string" ? (
                                    <Icon>{prop.icon}</Icon>
                                ) : (
                                    <IconBox
                                        bg="teal.300"
                                        color="white"
                                        h="30px"
                                        w="30px"
                                        me="12px"
                                    >
                                        {prop.icon}
                                    </IconBox>
                                )}
                                <Text color={activeColor} my="auto" fontSize="sm">
                                    {document.documentElement.dir === "rtl"
                                        ? prop.rtlName
                                        : prop.name}
                                </Text>
                            </Flex>
                        </Button>
                    ) : (
                        <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            bg="transparent"
                            mb={{
                                xl: "12px",
                            }}
                            mx={{
                                xl: "auto",
                            }}
                            py="12px"
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            borderRadius="15px"
                            _hover="none"
                            w="100%"
                            _active={{
                                bg: "inherit",
                                transform: "none",
                                borderColor: "transparent",
                            }}
                            _focus={{
                                boxShadow: "none",
                            }}
                        >
                            <Flex>
                                {typeof prop.icon === "string" ? (
                                    <Icon>{prop.icon}</Icon>
                                ) : (
                                    <IconBox
                                        bg={inactiveBg}
                                        color="teal.300"
                                        h="30px"
                                        w="30px"
                                        me="12px"
                                    >
                                        {prop.icon}
                                    </IconBox>
                                )}
                                <Text color={inactiveColor} my="auto" fontSize="sm">
                                    {document.documentElement.dir === "rtl"
                                        ? prop.rtlName
                                        : prop.name}
                                </Text>
                            </Flex>
                        </Button>
                    )}
                </NavLink>
            );
        });
    };

    return (
        <>
            <Box pt={"25px"} mb="12px">
                <Link
                    href={`${process.env.PUBLIC_URL}/#/`}
                    target="_blank"
                    display="flex"
                    lineHeight="100%"
                    mb="30px"
                    fontWeight="bold"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="11px"
                >
                    <PeakyBlinders w="150px" h="30px" me="-50px" />

                </Link>
                <Separator />
            </Box>
            <Stack direction="column" mb="40px">
                <Box>{createLinks(filteredRoutes)}</Box>
            </Stack>
        </>
    );
};

export default SidebarContent;
