import React, { useState } from 'react';
import { Box, Input, Textarea, Button, FormControl, FormLabel, useToast } from '@chakra-ui/react';
import axios from 'axios';

const SendEmail = () => {
    const [recipient, setRecipient] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const toast = useToast();

    const handleSendEmail = async () => {
        try {
            await axios.post('https://peakyemperium.api.mrapi.net/api/send-email', {
                sender: 'anthony.ghais@peaky-emporium.net', // Use a logged-in user for dynamic email
                recipient,
                subject,
                message
            });
            toast({
                title: "Email sent.",
                description: `Email sent to ${recipient}`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setRecipient('');
            setSubject('');
            setMessage('');
        } catch (error) {
            toast({
                title: "Error.",
                description: "There was an error sending your email.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box p={4} borderWidth="1px" borderRadius="lg" boxShadow="lg">
            <FormControl>
                <FormLabel>Recipient</FormLabel>
                <Input
                    value={recipient}
                    onChange={(e) => setRecipient(e.target.value)}
                    placeholder="prenom.nom@peaky-emporium.net"
                />
            </FormControl>
            <FormControl mt={4}>
                <FormLabel>Subject</FormLabel>
                <Input
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Email Subject"
                />
            </FormControl>
            <FormControl mt={4}>
                <FormLabel>Message</FormLabel>
                <Textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Your message"
                />
            </FormControl>
            <Button mt={4} onClick={handleSendEmail} colorScheme="blue">
                Send Email
            </Button>
        </Box>
    );
};

export default SendEmail;
