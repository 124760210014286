import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { roles } from './Permissions/RolesPermissions';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const history = useHistory(); 

  function getCookie(name) {
    const cookieArr = document.cookie.split(';');
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split('=');
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  useEffect(() => {
    const cookieToken = getCookie('peakyemperium_session');
    if (cookieToken) {
      setToken(cookieToken);
      setIsAuthenticated(true);
      history.push("/admin/dashboard");
    }
    setIsLoading(false);
  }, []);

  const fetchUserDetails = async (token) => {
    try {
      const response = await fetch('https://peakyemperium.api.mrapi.net/user', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des détails de l’utilisateur');
      }

      const userDetails = await response.json();
      setUserDetails(userDetails);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations de l’utilisateur', error);
    }
  };
  
  const login = (newToken) => {
    setToken(newToken);
    setIsAuthenticated(true);
    document.cookie = `peakyemperium_session=${newToken}; path=/; max-age=${30 * 24 * 60 * 60}; secure; samesite=strict`;
    history.push("/admin/dashboard");
  };
  
  const logout = () => {
    document.cookie = 'peakyemperium_session=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    setToken(null);
    setIsAuthenticated(false);
    setUserDetails(null);
  };

  const getUserRole = () => {
    if (userDetails && userDetails.grade !== undefined) {
      return Object.keys(roles).find(role => roles[role].grade === userDetails.grade);
    }
    return null;
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout, token, userDetails, fetchUserDetails, getUserRole }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
