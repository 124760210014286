// routes.js
import Dashboard1 from "views/Dashboard/Dashboard/index2.js";
import CoffreEntrepot from "views/Dashboard/Entrepot";
import Tables from "views/Dashboard/Tables";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

//Custom
import UsersList from "views/Admin/Users/list";
import EditUser from "views/Admin/Users/edit";
import CreateUser from "views/Admin/Users/create";
import ArgentPerUser from "views/Dashboard/Comptabilite/argentperusers";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  RocketIcon,
} from "components/Icons/Icons";
import { FaDiscord } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineInventory2 } from "react-icons/md";
import QuotaPage from "views/Dashboard/Works/qoata";
import DiscordApp from "views/Admin/Discord";
import DMList from "views/Admin/Discord/DmMessage";
import VenteDrogue from "views/Dashboard/Comptabilite/VenteDrogue";
import CoffreBouffe from "views/Dashboard/Entrepot/CoffreBouffe";
import CoffreDrogue from "views/Dashboard/Entrepot/CoffreDrogue";
import CoffreLabo from "views/Dashboard/Entrepot/CoffreLabo";
import DiscordGuildLogMembers from "views/Admin/Discord/Guild/log";
import MemberCardlog from "views/Admin/Discord/Guild/log";
import TicketMessenger from "views/Admin/Discord/Tickets/Messenger";
import CreateRoleForm from "views/Admin/Discord/Guild/roles";
import CoffreLabo2 from "views/Dashboard/Entrepot/CoffreLabo2";
import CLabo from "views/Dashboard/Entrepot/CLabo";
import Branche from "views/Dashboard/Entrepot/CoffreBranche";
import CoffreDrogue2 from "views/Dashboard/Entrepot/CoffreDrogue2";
import CoffreDrogue3 from "views/Dashboard/Entrepot/CoffreDrogue3";
import CLabo2 from "views/Dashboard/Entrepot/CLabo2";
import EmailDashboard from "views/Dashboard/Messagerie";

// Define routes with requiredGrades and hidden properties
const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <MdOutlineDashboard color="inherit" />,
    component: Dashboard1,
    layout: "/admin",
    requiredGrades: [0, 40, 50, 85, 90, 94, 95, 100, 250, 255],
    hidden: false,
  },
  {
    path: "/Users",
    name: "Users Administration",
    icon: <FaRegUser color="inherit" />,
    component: UsersList,
    layout: "/admin",
    requiredGrades: [94, 95, 100, 250, 255],
    hidden: false,
  },
  {
    path: "/Users/Create",
    name: "Create User",
    component: CreateUser,
    layout: "/admin",
    requiredGrades: [100, 250, 255],
    hidden: true,
  },
  {
    path: "/Users/Edit/:id",
    name: "Edit User",
    component: EditUser,
    layout: "/admin",
    requiredGrades: [100, 250, 255],
    hidden: true,
  },
  {
    path: "/profile",
    name: "Profile",
    icon: <PersonIcon color="inherit" />,
    secondaryNavbar: true,
    component: Profile,
    layout: "/admin",
    hidden: true,
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 250, 255],
  },
  {
    path: "/messagerie",
    name: "Messagerie",
    icon: <PersonIcon color="inherit" />,
    secondaryNavbar: true,
    component: EmailDashboard,
    layout: "/admin",
    hidden: false,
    requiredGrades: [40, 50, 85, 90, 94, 95, 100, 250, 255],
  },
  {
    path: "/login",
    component: SignIn,
    layout: "/auth",
    hidden: true,
  },
  {
    path: "/register",
    name: "Sign Up",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: SignUp,
    layout: "/auth",
    hidden: true,
  },
];

export default dashRoutes;
