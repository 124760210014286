import React from 'react';
import { Grid, Box } from '@chakra-ui/react';
import SendEmail from './send';
import Inbox from './inbox';
const EmailDashboard = () => {
    return (
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Box>
                <SendEmail />
            </Box>
            <Box>
                <Inbox />
            </Box>
        </Grid>
    );
};

export default EmailDashboard;