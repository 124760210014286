import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Text, Badge, Spinner } from '@chakra-ui/react';

const StatusComponent = () => {
  const [statusData, setStatusData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStatusData = async () => {
      try {
        const response = await axios.get('https://status.cfx.re/api/v2/components.json');
        setStatusData(response.data);
        setIsLoading(false);
      } catch (err) {
        setError('Erreur lors de la récupération du statut');
        setIsLoading(false);
      }
    };

    fetchStatusData();
    const intervalId = setInterval(fetchStatusData, 30000);

    return () => clearInterval(intervalId);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  const filteredComponents = statusData?.components?.filter(
    component =>
      component.name.includes("Game") ||
      component.name.includes("FiveM") ||
      component.name.includes("Server List Frontend")
  );

  return (
    <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="md">
      <Text fontSize="lg" mb={4}>
        Statut de {statusData?.page?.name || 'Cfx.re'}
      </Text>
      
      {filteredComponents && filteredComponents.length > 0 ? (
        filteredComponents.map((component) => (
          <Box key={component.id} mt={2}>
            <Text fontSize="md">
              {component.name} :{' '}
              <Badge colorScheme={component.status === 'operational' ? 'green' : 'red'}>
                {component.status.replace('_', ' ')}
              </Badge>
            </Text>
          </Box>
        ))
      ) : (
        <Text>Aucune donnée pour la catégorie sélectionnée.</Text>
      )}
    </Box>
  );
};

export default StatusComponent;
