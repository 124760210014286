// roles.js
export const roles = {
    Développeur: {
        grade: 255,
        canAccessDashboard: true,
        canManageUsers: true,
        canViewReports: true,
    },
    System: {
      grade: 254,
      canAccessDashboard: true,
      canManageUsers: true,
      canViewReports: true,
    },
    Résponsable_Entreprise: {
      grade: 251,
      canAccessDashboard: true,
      canManageUsers: true,
      canViewReports: true,
    },
    Patron: {
        grade: 250,
        canAccessDashboard: true,
        canManageUsers: true,
        canViewReports: true,
      },
    CoPatron: {
      grade: 249,
      canAccessDashboard: true,
      canManageUsers: true,
      canViewReports: true,
    },
    Directeur_Ressource_Humaine: {
      grade: 240,
      canAccessDashboard: true,
      canManageUsers: true,
      canViewReports: true,
    },
    Résponsable_Stock: {
      grade: 230,
      canAccessDashboard: true,
      canManageUsers: false,
      canViewReports: true,
    },
    Formateurs: {
      grade: 220,
      canAccessDashboard: true,
      canManageUsers: false,
      canViewReports: false,
    },
    Agent_de_Sécurité: {
      grade: 210,
      canAccessDashboard: false,
      canManageUsers: false,
      canViewReports: false,
    },
    Livreur: {
      grade: 200,
      canAccessDashboard: false,
      canManageUsers: false,
      canViewReports: false,
    },
    Gérant: {
      grade: 199,
      canAccessDashboard: false,
      canManageUsers: false,
      canViewReports: false,
    },
    Employer: {
      grade: 198,
      canAccessDashboard: false,
      canManageUsers: false,
      canViewReports: false,
    },
  };

export const getGradeNumber = (roleName) => {
  return roles[roleName] ? roles[roleName].grade : null;
};
export const getRoleName = (gradeNumber) => {
  for (const [roleName, role] of Object.entries(roles)) {
    if (role.grade === gradeNumber) {
      return roleName;
    }
  }
  return null;
};
  