// Chakra imports
import { Flex, Icon, Link, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState, useEffect } from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import axios from 'axios';
import { useAuth } from "context/AuthContext";

const ProfileInformation = ({ title, userId }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  // State pour stocker les informations utilisateur
  const [userInfo, setUserInfo] = useState(null);

  // Récupération du token à partir du contexte d'authentification
  const { token } = useAuth();

  // Fonction pour récupérer les informations utilisateur depuis l'API
  const fetchUserInfo = async () => {
    try {
      const response = await axios.get('https://peaky.api.mrapi.net/user', {
        params: { discord_id: userId },  // Paramètres de la requête
        headers: {
          Authorization: `Bearer ${token}`,  // En-tête d'autorisation avec le token
        }
      });
      setUserInfo(response.data);
    } catch (error) {
      console.error('Error fetching user information:', error);
    }
  };

  // useEffect pour récupérer les données à l'initialisation du composant
  useEffect(() => {
    if (token) {
      fetchUserInfo();
    }
  }, [userId, token]);

  // Si les informations ne sont pas encore chargées, afficher un texte de chargement
  if (!userInfo) {
    return <Text>Loading...</Text>;
  }

  return (
    <Card p='16px' my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p='12px 5px' mb='12px'>
        <Text fontSize='lg' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody px='5px'>
        <Flex direction='column'>
          <Text fontSize='md' color='gray.500' fontWeight='400' mb='30px'>
           Détails des informations pour le compte : {userInfo.name}
          </Text>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Full Name:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {userInfo.firstname} {userInfo.lastname}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Email:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {userInfo.email}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Grade:{" "}
            </Text>
            <Text fontSize='md' color='gray.500' fontWeight='400'>
              {userInfo.grade}
            </Text>
          </Flex>
          <Flex align='center' mb='18px'>
            <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
              Avatar:{" "}
            </Text>
            <img src={userInfo.avatars} alt={`${userInfo.firstname} ${userInfo.lastname}`} width="50" height="50" />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ProfileInformation;
