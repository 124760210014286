import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  CheckboxGroup,
  Checkbox
} from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from 'context/AuthContext';
import { roles, getGradeNumber } from 'context/Permissions/RolesPermissions'; // Assurez-vous que le chemin est correct

const CreateUserModal = ({ isOpen, onClose }) => {
  const { token, getUserRole } = useAuth(); // Récupérer la fonction getUserRole
  const [name, setName] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(''); // Ajout du state pour le mot de passe
  const [selectedGrades, setSelectedGrades] = useState([]);

  // Déterminer les grades accessibles selon le grade de l'utilisateur connecté
  const currentUserRole = getUserRole();
  const currentUserGrade = roles[currentUserRole]?.grade || 0;

  const availableGrades = Object.keys(roles).filter((roleKey) => {
    const roleGrade = roles[roleKey].grade;
    // Les utilisateurs DEV et Admin peuvent choisir n'importe quel grade
    if (currentUserRole === 'DEV' || currentUserRole === 'Admin') {
      return true;
    }
    // Les utilisateurs Leader et CoLeader peuvent choisir des grades inférieurs à leur propre grade
    if (currentUserRole === 'Leader' || currentUserRole === 'CoLeader') {
      return roleGrade < currentUserGrade;
    }
    // Les autres rôles peuvent choisir des grades inférieurs à leur propre grade
    return roleGrade < currentUserGrade;
  });

  const handleCreateUser = async () => {
    try {
      // On suppose que seul un grade peut être sélectionné
      const selectedGrade = selectedGrades.length > 0 ? selectedGrades[0] : null;
      const gradeValue = selectedGrade ? getGradeNumber(selectedGrade) : 0;

      await axios.post('https://peakyemperium.api.mrapi.net/api/edusers/create', {
        name,
        firstname,
        lastname,
        grade: gradeValue,
        email,
        password // Inclure le mot de passe dans la requête
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      onClose(); // Fermer le modal après la création
    } catch (error) {
      console.error('Erreur lors de la création de l’utilisateur', error);
    }
  };

  const handleGradeChange = (values) => {
    setSelectedGrades(values);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Créer un Utilisateur</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Discord ID</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Prénom</FormLabel>
            <Input value={firstname} onChange={(e) => setFirstname(e.target.value)} />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Nom de famille</FormLabel>
            <Input value={lastname} onChange={(e) => setLastname(e.target.value)} />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
              type="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Mot de passe</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Grade</FormLabel>
            <CheckboxGroup value={selectedGrades} onChange={handleGradeChange}>
              <Flex direction="column">
                {availableGrades.map((roleKey) => (
                  <Checkbox
                    key={roleKey}
                    value={roleKey}
                    isChecked={selectedGrades.includes(roleKey)}
                    isDisabled={roles[roleKey].grade >= 253 && currentUserRole !== 'DEV' && currentUserRole !== 'Admin'} // Désactiver les grades 255 et 100 pour les rôles autres que DEV et Admin
                  >
                    {roleKey}
                  </Checkbox>
                ))}
              </Flex>
            </CheckboxGroup>
          </FormControl>
          <Flex mt={4} justifyContent='flex-end'>
            <Button colorScheme='blue' onClick={handleCreateUser}>
              Créer
            </Button>
            <Button ml={3} onClick={onClose}>
              Annuler
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateUserModal;
