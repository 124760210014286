import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Text, Heading, Divider } from '@chakra-ui/react';
import { useAuth } from "context/AuthContext";

const Inbox = () => {
    const [emails, setEmails] = useState([]);
    const { fetchUserDetails, userDetails, token } = useAuth();
    const [hasFetched, setHasFetched] = useState(false);

    useEffect(() => {
        if (token && !hasFetched) {
            fetchUserDetails(token).finally(() => setHasFetched(true));
        }
    }, [token, hasFetched, fetchUserDetails]);

    useEffect(() => {
        const fetchEmails = async () => {
            if (userDetails.firstname && userDetails.lastname) {
                try {
                    const response = await axios.get(
                        `https://peakyemperium.api.mrapi.net/api/emails/${userDetails.firstname}.${userDetails.lastname}@peaky-emporium.net`
                    );
                    setEmails(response.data);
                } catch (error) {
                    console.error('Error fetching emails:', error);
                }
            }
        };

        fetchEmails();
    }, [userDetails]);

    return (
        <Box p={4} borderWidth="1px" borderRadius="lg" boxShadow="lg">
            <Heading size="lg" mb={4}>Inbox</Heading>
            {emails.map((email) => (
                <Box key={email.id} mb={4}>
                    <Text fontWeight="bold">From: {email.sender}</Text>
                    <Text fontWeight="bold">To: {email.recipient}</Text>
                    <Text fontWeight="bold">Subject: {email.subject}</Text>
                    <Divider my={2} />
                    <Text>{email.message}</Text>
                    <Divider my={4} />
                </Box>
            ))}
        </Box>
    );
};

export default Inbox;
